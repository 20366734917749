.action-p {
  display: flex;
  gap: 5px;
}
.action-icon {
  font-size: 1.3rem;
  cursor: pointer;
  color: purple;
}
.connect-box {
  max-width: 700px !important;
}
.connect-boxp {
  max-width: 1000px !important;
}
.connect-boxEp {
  max-width: 900px !important;
}
.connect-boxorder {
  max-width: 1100px !important;
}
.delete-icons {
  color: red;
  font-size: 5rem;
}
